import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'vant/lib/index.css';
import { Overlay, Tab, Tabs, Lazyload, List } from 'vant';
const app = createApp(App)
app.use(Overlay)
app.use(Tab)
app.use(Tabs)
app.use(Lazyload)
app.use(List)
app.use(store).use(router).mount("#app");
