import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    meta: { title: "康苗星球" },
  },
  {
    path: "/inoculationGuidelines",
    name: "inoculationGuidelines",
    component: () => import("@/views/inoculationGuidelines.vue"),
    meta: { title: "地图列表" },
  },
  {
    path: "/lnsmb",
    name: "lnsmb",
    component: () => import("@/views//luodi/lnsmb.vue"),
    meta: { title: "流脑扫盲班" },
  },
  {
    path: "/kjym",
    name: "kjym",
    component: () => import("@/views//luodi/kjym.vue"),
    meta: { title: "康家疫苗" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
router.beforeEach((to, from, next) => {
  // 检查路由是否定义了 meta 标题
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})